import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Loading, Modal, RadioTile, Separator, toast, Tooltip, Typography } from '@notch-ordering/ui-components';
import { useInvoiceStore } from '@ar/stores/InvoicesStore';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import InfoOutlineIcon from '@icons/info-outline-icon.svg';
import BigNumber from 'bignumber.js';
import { GetPaymentMethodResponse, payInvoices } from '@ar/network/AccountsReceivable.network';
import { FETCH_TRANSACTION_ACTIVITY_QUERY_KEY,
    FETCH_TRANSACTION_QUERY_KEY,
    FETCH_TRANSACTIONS_QUERY_KEY } from '@ar/hooks/queries/InvoicesQueries.hook';
import { getGpoId } from '@v2/utils/GPOUtils';
import { LoadingPaymentMethodBanner } from '@ar/components/Invoices/Modals/BulkManualChargeModal/LoadingPaymentMethodBanner';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getCurrencyFormat } from '@v2/utils/CurrencyUtils';
import { isSurchargeEnabled } from '@v2/utils/Surcharge';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';
import { AmplitudeCharge, AmplitudeEventActions, useAmplitude } from '@/containers/app/AmplitudeContext';
import { RutterSetupNotCompleteBanner } from '../../shared/RutterSetupNotCompleteBanner';
import { useRutterSetupStatus } from '@/ar/hooks/queries/IntegrationsQuery.hook';
import PaymentMethodBadge from '../../shared/PaymentMethodBadge/PaymentMethodBadge';
import { useGetCustomer, useGetCustomerPaymentMethods } from '@/ar/hooks/queries/CustomerQueries.hook';
import { formatSurchargeAmount, getEstimatedSurcharge } from './BulkManualChargeModal/BulkManualChargeModalConstants';

export const ManualChargeModal : React.FC = () => {
    const { modals, setModal } = useInvoiceStore();
    const { supplierLoginData } = useSupplierStore();
    const gpoId = getGpoId(supplierLoginData);
    const queryClient = useQueryClient();
    const { trackAmplitudeEvent } = useAmplitude();
    const currentTransaction = modals.transaction;
    const supplierID = supplierLoginData?.supplier_id ?? currentTransaction?.supplier_id;
    const { isRutterSetupNotComplete } = useRutterSetupStatus(supplierLoginData.supplier_id);
    const flags = useFlags();

    const { data: customer } = useGetCustomer({
        customerID: currentTransaction?.customer_id,
        supplierID: supplierLoginData?.supplier_id,
        gpoId
    });
    const [isUsingSurcharges, setIsUsingSurcharges] = useState<boolean>(isSurchargeEnabled(currentTransaction ? [currentTransaction] : [], flags, supplierLoginData?.isSupplier, supplierLoginData?.gpo));

    const { data: paymentMethodsResponse } = useGetCustomerPaymentMethods({
        supplierID,
        customerID: currentTransaction?.customer_id,
        gpoId: getGpoId(supplierLoginData),
    });

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<GetPaymentMethodResponse>(null);
    const [amount, setAmount] = useState<number>(centsToDollars(currentTransaction?.outstanding_amount) ?? 0);
    const [estimatedSurcharges, setEstimatedSurcharges] = useState<BigNumber>(new BigNumber(0));
    const [total, setTotal] = useState<BigNumber>(new BigNumber(amount));
    const [showSurchargeTooltip, setShowSurchargeTooltip] = useState(false);
    const isModalOpen = modals.manualCharge;
    const hasIncompletePaymentMethodSelected = selectedPaymentMethod && !selectedPaymentMethod?.last4;

    const formIsInvalid = ():boolean => !selectedPaymentMethod || !amount || amount > currentTransaction?.outstanding_amount;
    const handleOnClose = ():void => {
        setSelectedPaymentMethod(null);
        setAmount(0);
        setTotal(BigNumber(0));
        queryClient.invalidateQueries([FETCH_TRANSACTIONS_QUERY_KEY]);
        queryClient.invalidateQueries([FETCH_TRANSACTION_ACTIVITY_QUERY_KEY]);
        queryClient.invalidateQueries([FETCH_TRANSACTION_QUERY_KEY]);
        setModal('manualCharge', false, null);
    };
    useEffect(() => {
        if (isModalOpen) {
            trackAmplitudeEvent(AmplitudeEventActions.pageChargeViewed, { type: AmplitudeCharge.individualCharge, transaction: currentTransaction?.id, countInvoices: 1 });
        }
    }, [isModalOpen]);
    const payInvoicesMutation = useMutation(payInvoices, {
        onSuccess: (_data) => {
            const paidSuccessful = _data[0].charged;
            let toastMessage;
            if (paidSuccessful) {
                toastMessage = `${getCurrencyFormat(total)} charged for Invoice #${currentTransaction?.invoice_number}`;
            } else {
                toastMessage = 'Charge has failed';
            }
            toast.show({
                message: toastMessage,
            });
            trackAmplitudeEvent(AmplitudeEventActions.charged, { type: AmplitudeCharge.individualCharge, transaction: currentTransaction?.id, countInvoices: 1 });
            handleOnClose();
        },
        onError: (error) => {
            toast.show({
                message: 'Something went wrong. Please try again.',
            });
            console.info(error);
        },
    });
    const handleCharge = ():void => {
        if (formIsInvalid()) {
            return;
        }
        trackAmplitudeEvent(AmplitudeEventActions.pushCharge, { type: AmplitudeCharge.individualCharge, transaction: currentTransaction?.id, countInvoices: 1 });
        payInvoicesMutation.mutate({
            supplierID: supplierLoginData?.supplier_id,
            gpoId: getGpoId(supplierLoginData),
            body: {
                is_lumped: false,
                payment_method_id: selectedPaymentMethod?.id,
                transaction_ids: [currentTransaction?.id],
            },
        });
    };

    useEffect(() => {
        if (currentTransaction) {
            setAmount(centsToDollars(currentTransaction?.outstanding_amount));
        }
    }, [currentTransaction]);

    useEffect(() => {
        const defaultMethod = paymentMethodsResponse?.payment_methods.filter((method) => method.is_primary)[0];
        setSelectedPaymentMethod(defaultMethod);
    }, [paymentMethodsResponse]);

    useEffect(() => {
        if (isUsingSurcharges) {
            setTotal(estimatedSurcharges.plus(new BigNumber(amount)));
        } else {
            setTotal(new BigNumber(amount));
        }
    }, [estimatedSurcharges, isUsingSurcharges, amount]);

    useEffect(() => {
        if (isUsingSurcharges) {
            setEstimatedSurcharges(getEstimatedSurcharge(selectedPaymentMethod, currentTransaction?.estimated_surcharges));
        }
    }, [selectedPaymentMethod, isUsingSurcharges]);

    useEffect(() => {
        setIsUsingSurcharges(isSurchargeEnabled(currentTransaction ? [currentTransaction] : [], flags, supplierLoginData?.isSupplier, supplierLoginData?.gpo));
    }, [customer, currentTransaction]);

    if (!currentTransaction || !isModalOpen) return null;
    return <Modal isOpen={isModalOpen}
        title={
            <><Typography className="m-1 pr-10" weight="font-semibold" variant="XL">Confirm charge(s)</Typography></>
        }
        headerPadding="mt-0 mb-5"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL"
        desktopModalWidth="lg:w-[480px]">
        <div className="p-6">
            {isRutterSetupNotComplete && <div className="pb-5">
                <RutterSetupNotCompleteBanner />
            </div>}
            <div className="flex-col flex gap-0">
                <div className="flex flex-col w-full">
                    <div className="flex flex-row justify-between">
                        <Typography variant="LG-2" weight="font-medium" className="mb-2">{currentTransaction?.business_name}</Typography>
                        <Typography variant="LG-2" weight="font-medium" className="mb-2">{getCurrencyFormat(total)}</Typography>
                    </div>
                    <div className="flex flex-row justify-between mb-3.5">
                        <Typography variant="LG-1" weight="font-regular" className="text-gray-650">1 invoice</Typography>
                        <Typography variant="LG-1" weight="font-regular" className="text-gray-650">{formatAsCurrency(amount)}</Typography>
                    </div>
                    {paymentMethodsResponse?.payment_methods?.length ? (
                        <div className="flex flex-row justify-between">
                            <Typography variant="BASE" weight="font-medium" className="text-gray-650 mb-2">Select payment method</Typography>
                            {isUsingSurcharges && <div className="flex flex-row gap-1">
                                <Typography variant="BASE" weight="font-medium" className="text-gray-650 mb-2">Surcharge</Typography>
                                <Tooltip show={showSurchargeTooltip}
                                    tooltipClassName="w-52 py-2.5 px-2.5"
                                    showArrow={false}
                                    placement="top-end"
                                    trigger={
                                        <div onMouseEnter={(): void => setShowSurchargeTooltip(true)} onMouseLeave={(): void => setShowSurchargeTooltip(false)}>
                                            <InfoOutlineIcon className="w-3 h-3 mb-px text-gray-600"/>
                                        </div>}>
                                    <div>
                                        <span>A surcharge may be applied when payments are made using a credit card.</span>
                                    </div>
                                </Tooltip>
                            </div>}
                        </div>) : null}
                    <div className="mb-6 h-9.5">
                        {paymentMethodsResponse?.payment_methods?.length ? (
                            <div className="flex flex-col items-center justify-between gap-2">
                                {paymentMethodsResponse?.payment_methods.map((paymentMethod) => <RadioTile
                                    className="w-full border rounded items-center pt-1"
                                    key={paymentMethod.id}
                                    value={paymentMethod.id}
                                    onChange={(): void => {
                                        setSelectedPaymentMethod(paymentMethod);
                                    }}
                                    selected={paymentMethod === selectedPaymentMethod}>
                                    <div className="w-full flex flex-row justify-between items-center mx-2">
                                        <PaymentMethodBadge
                                            className="items-center my-0"
                                            key={paymentMethod.id}
                                            brand={paymentMethod.brand}
                                            last4={paymentMethod.last4}
                                            exp_month={paymentMethod.exp_month}
                                            exp_year={paymentMethod.exp_year}
                                            isDefault={paymentMethod.is_primary} />
                                        {isUsingSurcharges && <Typography variant="LG-1" weight="font-regular" className="text-gray-650 m-0 mb-1">{formatSurchargeAmount(getEstimatedSurcharge(paymentMethod, currentTransaction?.estimated_surcharges).times(100))}</Typography>}
                                    </div>
                                </RadioTile>)
                                }
                            </div>) : null}
                        <Button
                            onClick={() => setModal('addPaymentMethod', true, currentTransaction)}
                            size="SMALL"
                            variant="TEXT"
                            className="text-1 p-0 pt-2">
                            Add payment method
                        </Button>
                    </div>

                </div>
            </div>
        </div>
        {hasIncompletePaymentMethodSelected && <LoadingPaymentMethodBanner/>}
        <Separator/>
        <div className="pt-5 px-5 flex justify-end gap-3">
            <Button variant="TERTIARY_FILLED"
                size="SMALL"
                onClick={handleOnClose}>
                <Typography as="span" weight="font-medium">
                    Cancel
                </Typography>
            </Button>
            <Button variant="SECONDARY"
                disabled={payInvoicesMutation.isLoading || hasIncompletePaymentMethodSelected}
                loading={payInvoicesMutation.isLoading}
                size="SMALL"
                onClick={handleCharge}>
                <Typography as="span" weight="font-medium">
                    {payInvoicesMutation.isLoading ? <Loading/> : `Charge ${getCurrencyFormat(total)}`}
                </Typography>
            </Button>
        </div>
    </Modal>;
};
