import ReactDOM from "react-dom";
import React from "react";
import {AppContainer} from "react-hot-loader";
// Required import to polyfill Array.flat() method
import "core-js/features/array/flat";
// Required as a replacement for babel polyfill after its deprecation
import "core-js/stable";
import "regenerator-runtime/runtime";

import configureStore from "../../store/configureStore";
import Root from "./Root";
import {setStore} from "@/api/DataAPI";
import Utils from "../../utils";
import {isPlatform, setupIonicReact} from "@ionic/react";
import {ScreenOrientation} from "@awesome-cordova-plugins/screen-orientation";
import {EIonicPlatforms} from "@/constants/Mobile";
import {App} from "@capacitor/app";
import chalk from "chalk";
import packageJson from "../../../package.json";
import "../../index.css";
// import i18n (needs to be bundled)
import "@v2/i18n";
import {Debug} from "@v2/utils/Debug";
import {asyncWithLDProvider} from 'launchdarkly-react-client-sdk'
import { AmplitudeProvider } from "@/containers/app/AmplitudeContext";


document.addEventListener("deviceready", onDeviceReady, false);

setupIonicReact();

export const store = configureStore();

// Pass store instance to any Singleton that needs it.
setStore(store);

// Check if sessionKey and refreshToken is passed through the URL then it's an impersonated session
const params = Utils.getParams(window.location.href);

const sessionKey = params["sessionKey"];
const refreshToken = params["refreshToken"];
const expiresAt = params["expiresAt"];

const canaryRelease = params["canary"];

if (sessionKey && refreshToken && expiresAt) {
  // Clear existing session tokens from local storage
  window.localStorage.setItem("chefhero_sk", sessionKey);
  window.localStorage.setItem("chefhero_rt", refreshToken);
  window.localStorage.setItem("chefhero_e", expiresAt);
  window.localStorage.removeItem("chefhero_defb");
}

if (canaryRelease) {
  window.canaryRelease = canaryRelease;
}

const fromSubdomain = params["from"];
if (fromSubdomain) {
  window.fromSubdomain = fromSubdomain;
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.CLIENT_SIDE_ID,
    context: {
      kind: 'user',
      anonymous: true
    },
    /* Specify the valid feature flags and their default fallback values, as outlined in the LaunchDarkly documentation:
    https://docs.launchdarkly.com/sdk/client-side/react/react-web#configuration-options */
    flags: {
      'amplitudeEnabled': true,
      'amplitudeSessionReplayTrackingEnabled': true,
      'amplitudeSessionReplayTrackingSampleRate': 100,
      'ar-payment-provider': { card: 'adyen', acss_debit: 'stripe', us_bank_account: 'stripe' },
      'customer_portal_invites': true,
      'customer_portal_payment_methods_settings': false,
      'qboIntegrationType': 'workato',
      'supplier-public-invoice-link': false,
      'payment-surcharges': { kind: 'arSuppliers', key:'', default: false },
      'ar-invoice-editor': false,
    },
  });

if (process.env.DEPLOY_ENV !== "development") {
  ReactDOM.render(<LDProvider><AmplitudeProvider><Root store={store} /></AmplitudeProvider></LDProvider>, document.getElementById("root"));
} else {
  ReactDOM.render(
    <LDProvider>
      <AmplitudeProvider>
        <AppContainer>
          <Root store={store} />
        </AppContainer>
      </AmplitudeProvider>
    </LDProvider>
    ,
    document.getElementById("root")
  );
  if (module.hot) {
    module.hot.accept("./Root", () => {
      const NextRoot = require("./Root").default;

      ReactDOM.render(
        <LDProvider>
          <AmplitudeProvider>
            <AppContainer>
             <NextRoot store={store} />
            </AppContainer>
          </AmplitudeProvider>
        </LDProvider>
        ,
        document.getElementById("root")
      );
    });
  }
}
})();

let versionText;
switch (process.env.DEPLOY_ENV) {
  case "production":
    versionText = chalk.black.bgMagenta.bold;
    break;
  case "staging":
    versionText = chalk.black.bgBlue.bold;
    break;
  default:
    versionText = chalk.black.bgGreen.bold;
}

console.log(
  versionText(
    `===v${packageJson.version} - ${process.env.DEPLOY_ENV.toUpperCase()}===`
  )
);

// Setup dev cheats
export let debug;
if (process.env.DEPLOY_ENV !== "production") {
  debug = new Debug();
  if (debug) {
    console.log(chalk.green.bgBlack.bold(`===DEBUG ENABLED===`));
  }
}

function onDeviceReady() {
  // Native mobile apps running on phone are locked to portrait
  if (
    isPlatform(EIonicPlatforms.MOBILE) &&
    !isPlatform(EIonicPlatforms.TABLET)
  ) {
    ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
  }

  App.addListener("appUrlOpen", (event) => {
    // Support for login through text
    // Mobile now uses the same web login flow
    const linkToken = "?link=";
    const linkIndex = event.url.indexOf(linkToken);
    if (linkIndex > -1) {
      const link = event.url.substring(linkIndex + linkToken.length);
      if (link) {
        // Links are expected to be encrypted
        const encryptedParams = link.substring(link.lastIndexOf("/") + 1);
        const decodedEncryptedParams = decodeURIComponent(encryptedParams);
        const decodedParams = atob(decodedEncryptedParams);
        if (decodedParams) {
          // For security reasons just supporting store links with parameters
          const url = `./store/${decodedParams}`;
          window.location.href = url;
        }
      }
    }
  });

}
