import React from 'react';
import { Banner, Button, EBannerType, Loading, Separator, toast, Typography } from '@notch-ordering/ui-components';
import cx from 'classnames';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { logError } from '@v2/utils/logError';
import { useMutation } from '@tanstack/react-query';
import { createSetupIntent } from '@ar/network/AccountsReceivable.network';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import BuildingBalanceIcon from '@icons/building-balance-icon.svg';
import InfoIcon from '@icons/info-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import { getGPOSupplierAPIToken } from '@v2/utils/GPOUtils';

export interface ACHButtonProps {
    customerID: string,
    supplierID: string,
    email: string,
    name: string,
    onSuccessfulSetup?: () => void,
}

type ACHButtonViewState = {
    requiresConfirmation: boolean,
    requiresAction: boolean,
    clientSecret: string,
    errorIntentMessage: string,
    isLoadingSetupIntent: boolean,
    isLoadingConfirmation: boolean,
};
const VIEW_STATE_INITIAL: ACHButtonViewState = {
    requiresConfirmation: false,
    requiresAction: false,
    clientSecret: '',
    errorIntentMessage: '',
    isLoadingSetupIntent: false,
    isLoadingConfirmation: false
};

export const ACHButton : React.FC<ACHButtonProps> = ({
    supplierID, customerID,
    name, email,
    onSuccessfulSetup
}) => {
    const supplierToken = getGPOSupplierAPIToken(supplierID);
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices.PayModal' });
    const createSetupIntentMutation = useMutation(createSetupIntent);
    const [viewState, setViewState] = React.useState<ACHButtonViewState>(VIEW_STATE_INITIAL);

    const showConfirmationView = viewState.requiresConfirmation && !viewState.requiresAction;
    const showButtonView = !viewState.requiresConfirmation;
    const updateViewState = (newState: Partial<ACHButtonViewState>) => {
        setViewState((prevState) => ({
            ...prevState,
            ...newState
        }));
    };

    const getStripeClient = async () => {
        const stripe: Stripe | void = await loadStripe(process.env.STRIPE_US_API_KEY)
            .catch((reason) => {
                logError('Failed to initialize Stripe.js', reason, true);
            });

        if (!stripe) {
            return null;
        }
        return stripe;
    };
    const setupStripeACH = async () => {
        try {
            updateViewState({ isLoadingSetupIntent: true });
            const intentResponse = await createSetupIntentMutation.mutateAsync({
                customerID,
                supplierID,
                token: supplierToken
            });
            if (intentResponse.client_secret) {
                updateViewState({ clientSecret: intentResponse.client_secret });
                const stripe: Stripe | void = await getStripeClient();
                const { setupIntent, error } = await stripe.collectBankAccountForSetup(
                    {
                        clientSecret: intentResponse.client_secret,
                        params: {
                            payment_method_type: 'us_bank_account',
                            payment_method_data: {
                                billing_details: {
                                    email,
                                    name,
                                }
                            }
                        }
                    }
                );

                updateViewState({ isLoadingSetupIntent: false });
                if (error) {
                    updateViewState({ errorIntentMessage: error.message });

                    return;
                }
                switch (setupIntent.status) {
                    case 'succeeded':
                        toast.show({
                            icon: <CheckIcon/>,
                            message: t('padInfoSuccess')
                        });
                        break;
                    case 'requires_payment_method':
                        toast.show({
                            icon: <InfoIcon/>,
                            message: 'Confirmation failed. Attempt again with a different payment method.'
                        });
                        break;
                    case 'requires_confirmation':
                        updateViewState({ requiresConfirmation: true });
                        break;
                    default:
                        toast.show({
                            message: t('transactionCanceled')
                        });
                        break;
                }
            }
        } catch (e) {
            updateViewState({ isLoadingSetupIntent: false });
            toast.show({
                message: 'Failed to setup bank account. Please try again.'
            });
        }
    };

    const confirmSetup = async () => {
        try {
            const stripe: Stripe | void = await getStripeClient();
            if (!stripe) {
                return;
            }
            updateViewState({ isLoadingConfirmation: true });
            const { error, setupIntent } = await stripe.confirmUsBankAccountSetup(viewState.clientSecret);
            updateViewState({ isLoadingConfirmation: false });
            if (error) {
                updateViewState({ errorIntentMessage: error.message });
                return;
            }

            if (setupIntent.next_action?.type === 'verify_with_microdeposits') {
                updateViewState({ requiresAction: true });
                return;
            }
            switch (setupIntent.status) {
                case 'succeeded':
                    toast.show({
                        icon: <CheckIcon/>,
                        message: t('padInfoSuccess')
                    });
                    if (onSuccessfulSetup) {
                        updateViewState(VIEW_STATE_INITIAL);
                        onSuccessfulSetup();
                    }
                    break;
                case 'requires_payment_method':
                    toast.show({
                        message: 'Confirmation failed. Attempt again with a different payment method.'
                    });
                    updateViewState({ requiresConfirmation: false });
                    break;
                case 'canceled':
                default:
                    toast.show({
                        message: t('transactionCanceled')
                    });
                    updateViewState({ requiresConfirmation: true });
                    break;
            }
        } catch (e) {
            updateViewState({ isLoadingConfirmation: false });
            toast.show({
                message: 'Failed to confirm bank account setup. Please try again.'
            });
        }
    };
    return <>
        {showConfirmationView && <div>
            <div className="flex flex-col gap-5 items-center">
                <Typography className="text-gray-600">
                    {'By clicking [accept], you are signing up for direct debits from Notch. By doing this, you are authorizing Notch, and, if applicable, its affiliated entities to debit the bank account specified above for any amount owed for charges arising from your use of Notch\'s services and/or purchase of products from Suppliers on the Notch Platform, pursuant to Notch and the supplier\'s website and terms, until this authorization is revoked.}'}
                </Typography>
                <Typography className="text-gray-600">
                    {'You are authorizing Notch, and, if applicable, its affiliated entities to debit your bank account periodically if and when you use Notch\'s services or purchase more than one of a Supplier on the Notch Platform\'s products periodically pursuant to Notch and the Supplier\'s terms. Payments that fall outside of the periodic debits authorized above will only be debited after your authorization is obtained.'}
                </Typography>
                <Typography className="text-gray-600">
                    {'You may amend or cancel this authorization at any time by providing notice to Notch with 30 (thirty) days notice.'}
                </Typography>
                <Separator/>
                <div className="flex gap-5">
                    <Button
                        variant="TERTIARY_OUTLINED"
                        size="MEDIUM"
                        onClick={(): void => updateViewState({ requiresConfirmation: false })}>
                        Cancel
                    </Button>
                    <Button
                        variant="PRIMARY"
                        size="MEDIUM"
                        loading={viewState.isLoadingConfirmation}
                        onClick={confirmSetup}>
                        Accept
                    </Button>
                </div>

            </div>
        </div>}
        {showButtonView && <Button
            className={cx('text-left flex gap-2 items-center px-4 py-3 text-gray-700 w-full')}
            variant="TERTIARY_OUTLINED"
            size="MEDIUM"
            onClick={setupStripeACH}>
            <div className="flex gap-3">
                <BuildingBalanceIcon
                    className="h-4 w-4"/> Set up US Bank Account
                {viewState.isLoadingSetupIntent && <Loading isDark/>}
            </div>
        </Button>}
        {viewState.requiresAction && <div>
            <Typography className="text-gray-600">
                As a security precaution, Stripe will perform a test deposit into the bank account for verification purposes. Stripe will send an email to your billing address with the next steps.
            </Typography>
        </div>}
        {viewState.errorIntentMessage
            && <div className="w-full mb-4">
                <Banner alertType={EBannerType.ERROR}
                    body={<Typography as="div" className="text-gray-600">
                        {viewState.errorIntentMessage}
                    </Typography>}
                    icon={<InfoIcon className="w-5 h-5 text-red-300"/>}
                    isDismissable={false}/>
            </div>
        }
    </>;
};
