import axios from 'axios';

import z from 'zod';
import { isValid, parse } from 'date-fns';
import { BUSHWHACK_API, BUSHWHACK_API_KEY } from '@/utils/constants';
import { getSupplierToken } from './AccountsReceivable.network';

export interface SupplierConfigurationData {
    arApiToken: string,
    platform: ERutterPlatform,
    rutterAccessToken: string,
    rutterConnectionID: string,
    syncExistingArStripeCustomers: boolean,
    rutterPaymentAccountID?: string,
}

export interface SupplierConfiguration {
    id: string,
    ownerID: string,
    type: EConfigurationType,
    data: SupplierConfigurationData,
    isEnabled: boolean,
    createdAt: string,
    updatedAt: string,
}

export interface GetSupplierConfigurationResponse {
    results: SupplierConfiguration[],
    hasNextPage: boolean,
    cursor: string | null,
    total: number,
}

export enum EConfigurationType {
    Fidelio = 'fidelio',
    ApRutter = 'apRutter',
    ArRutter = 'arRutter',
    Workato = 'workato',
    Spire = 'spire',
    BigChip = 'big-chip',
    Sage300 = 'sage-300',
}

export enum ERutterPlatform {
    NETSUITE = 'Netsuite',
    QUICKBOOKS = 'Quickbooks',
}

export type RutterConnectionStatus = {
    connectionID: string | null,
    isReady: boolean,
    isDisabled: boolean,
    disabledReason: string | null,
    lastSyncCompletedAt: string,
    platform: string | null,
};

/**
 * get supplier configuration from bushwhack
 */
export async function getSupplierConfiguration(supplierID: string): Promise<GetSupplierConfigurationResponse> {
    return axios.get(`${BUSHWHACK_API}/configurations?ownerID=${supplierID}&key=${BUSHWHACK_API_KEY}`).then((response) => response.data);
}

export const ConfigurationRecordSchema = z.object({
    id: z.string().uuid(),
    ownerID: z.string().trim().min(1),
    type: z.nativeEnum(EConfigurationType),
    data: z.record(z.string(), z.unknown()),
    isEnabled: z.boolean().default(true),
});

export type ConfigurationRecord = z.infer<typeof ConfigurationRecordSchema>;

export const FidelioConfigurationDataSchema = z.object({
    url: z.string().url(),
    environmentName: z.string().trim().min(1),
    department: z.string().trim().min(1).optional(),
    pollDelaySeconds: z.number().gt(0).default(900), // Poll every 15 min by default
    arApiToken: z.string().optional(),
});

export type FidelioIntegrationConfigurationPayload = z.infer<typeof FidelioConfigurationDataSchema>;

export type UpdateSupplierConfigurationPayload = {
    data: FidelioIntegrationConfigurationPayload,
    isEnabled: boolean,
    configurationID?: string,
    ownerID?: string,
    type?: EConfigurationType,
};

export const SpireConfigurationDataSchema = z.object({
    url: z.string().url(),
    credentials: z.string().trim().min(1),
    nonce: z.string().trim().min(1),
    arApiToken: z.string().min(1),
    companyName: z.string().trim().min(1).optional(),
    spirePaymentMethodID: z.number().int().gt(0).optional(),
    invoiceSyncStartDate: z.string().refine((arg) => isValid(parse(arg, 'yyyy-MM-dd', new Date()))).optional(),
    pollDelaySeconds: z.number().gt(0).default(900), // Poll every 15 min by default
});
export type SpireConfigurationDataDto = z.infer<typeof SpireConfigurationDataSchema>;

export const AuthenticateSpireConfigurationBodySchema = z.object({
    url: z.string().url(),
    username: z.string().trim().min(1),
    password: z.string().trim().min(1),
    arSupplierID: z.string().uuid(),
    arSupplierToken: z.string().uuid(),
});
export type AuthenticateSpireConfigurationBodyDto = z.infer<typeof AuthenticateSpireConfigurationBodySchema>;

export const AuthenticateSage300BodySchema = z.object({
    url: z.string().url(),
    username: z.string().trim().min(1),
    password: z.string().trim().min(1),
    arSupplierID: z.string().uuid(),
    arSupplierToken: z.string().uuid(),
    invoiceSyncStartDate: z.string().refine((arg) => isValid(parse(arg, 'yyyy-MM-dd', new Date()))).optional(),
    refundRevenueAccount: z.string().trim().min(1).optional(),
    refundBankCode: z.string().trim().min(1).optional(),
    isEnabled: z.boolean().optional().default(true),
});
export type AuthenticateSage300BodyDto = z.infer<typeof AuthenticateSage300BodySchema>;

/**
 * update supplier configuration in bushwhack
 */
export async function updateIntegrationConfiguration(params: UpdateIntegrationParams): Promise<SupplierConfiguration> {
    const payload:UpdateIntegrationParams = {
        ...params,
        data: {
            ...params.data,
            arApiToken: await getSupplierToken(params.ownerID),
        },
    };
    return axios.patch(`${BUSHWHACK_API}/configurations/${params.id}?key=${BUSHWHACK_API_KEY}`, payload).then((response) => response.data);
}

export type UpdateIntegrationParams = ConfigurationRecord & {
    data: FidelioIntegrationConfigurationPayload | SpireConfigurationDataDto,
};
/**
 *
 */
export async function createIntegrationConfiguration(params: UpdateIntegrationParams): Promise<SupplierConfiguration> {
    const payload:UpdateIntegrationParams = {
        ...params,
        data: {
            ...params.data,
            arApiToken: await getSupplierToken(params.ownerID),
        },

    };
    return axios.post(`${BUSHWHACK_API}/configurations?key=${BUSHWHACK_API_KEY}`, payload).then((response) => response.data);
}

export async function authenticateSage300Configuration(params: AuthenticateSage300BodyDto): Promise<SupplierConfiguration> {
    return axios.post(`${BUSHWHACK_API}/sage-300/login?key=${BUSHWHACK_API_KEY}`, params).then((response) => response.data);
}

export const ResyncInvoiceRequestBodySchema = z.object({
    supplierID: z.string().uuid(),
    transactionID: z.string().uuid(),
});
export type ResyncInvoiceRequestParamsBodyDto = z.infer<typeof ResyncInvoiceRequestBodySchema>;

/**
 * Resync invoice from Sage 300
 */
export async function resyncSage300Invoice(payload: ResyncInvoiceRequestParamsBodyDto): Promise<void> {
    return axios.post(`${BUSHWHACK_API}/sage-300/resync-transaction?key=${BUSHWHACK_API_KEY}`, payload).then((response) => response.data);
}

/**
 *
 */
export async function deleteIntegrationConfiguration(integrationID:string): Promise<void> {
    return axios.delete(`${BUSHWHACK_API}/configurations/${integrationID}?key=${BUSHWHACK_API_KEY}`).then((response) => response.data);
}

export interface ExchangeRutterPublicTokenBody {
    rutterToken: string,
    ownerID: string,
    arApiToken: string,
    syncExistingArStripeCustomers?: boolean,
}

export interface ExchangeRutterPublicTokenResponse {
    id: string,
    ownerID: string,
    type: string,
    data: {
        platform: string,
        arApiToken: string,
        rutterAccessToken: string,
        rutterConnectionID: string,
        syncExistingArStripeCustomers: boolean,
    },
    isEnabled: boolean,
    createdAt: string,
    updatedAt: string,
}

/**
 * exchange rutter public_token for access_token
 */
export async function exchangeRutterToken(body: ExchangeRutterPublicTokenBody): Promise<ExchangeRutterPublicTokenResponse> {
    return axios.post(`${BUSHWHACK_API}/rutter/ar/exchangeToken`, {
        ...body,
    }).then((response) => response.data);
}

export type RutterAccountType = 'accounts_payable' | 'accounts_receivable' | 'bank' | 'fixed_asset' | 'other_asset' |
'other_current_asset' | 'liability' | 'equity' | 'expense' | 'other_expense' | 'income' | 'other_income' | 'credit_card' |
'cost_of_goods_sold' | 'other_current_liability' | 'long_term_liability' | 'non_posting' | 'unknown';

export type RutterAccountCategory = 'asset' | 'expense' | 'liability' | 'income' | 'nonposting' | 'unknown';

export type RutterAccountStatus = 'active' | 'inactive' | 'pending';

export type RutterAccount = {
    id: string,
    platformID: string,
    accountType: RutterAccountType,
    category: RutterAccountCategory,
    status: RutterAccountStatus,
    balance: number | null,
    currencyCode: string | null,
    name: string | null,
    nominalCode: string | null,
    subsidiaries: Array<{
        id: string,
    }>,
    createdAt: string | null,
    updatedAt: string | null,
};

export interface GetRutterAccountsResponse {
    accounts: RutterAccount[],
}

/**
 * get the rutter accounts associated with the connection
 */
export async function getRutterAccounts(supplierID: string, filterByAccountType?: Set<RutterAccountType>): Promise<GetRutterAccountsResponse> {
    const data = await axios.get(`${BUSHWHACK_API}/rutter/accounts`, {
        params: {
            ownerID: supplierID,
            key: BUSHWHACK_API_KEY
        }
    }).then((response) => response.data);

    if (filterByAccountType) {
        data.accounts = data.accounts.filter((account) => filterByAccountType.has(account.accountType));
    }

    return data;
}

/**
 * Spire
 */

/**
 *
 */
export async function spireLogin(params: AuthenticateSpireConfigurationBodyDto): Promise<UpdateIntegrationParams> {
    return axios.post(`${BUSHWHACK_API}/spire/login?key=${BUSHWHACK_API_KEY}`, params).then((response) => response.data);
}

export type SpireCompany = {
    name: string,
    description: string,
    url: string,
    isValid: boolean,
};

export type SpireCompaniesResponse = {
    companies: SpireCompany[],
};
/**
 *
 */
export async function getSpireCompanies(supplierID: string): Promise<SpireCompaniesResponse> {
    return axios.get(`${BUSHWHACK_API}/spire/companies`, {
        params: {
            key: BUSHWHACK_API_KEY,
            supplierID,
        },
    }).then((response) => response.data);
}

export type SpirePaymentMethod = {
    id: number,
    description: string,
};

export type SpirePaymentMethodsResponse = {
    paymentMethods: SpirePaymentMethod[],
};
export type SpireCompanyPaymentMethodParams = {
    supplierID: string,
    companyName: string,
    enabled?: boolean,
};
/**
 *
 */
export async function getSpireCompanyPaymentMethods(params:SpireCompanyPaymentMethodParams): Promise<SpirePaymentMethodsResponse> {
    const { supplierID, companyName } = params;
    return axios.get(`${BUSHWHACK_API}/spire/paymentMethods`, {
        params: {
            key: BUSHWHACK_API_KEY,
            supplierID,
            companyName,
        },
    }).then((response) => response.data);
}

export async function getRutterConfiguration(supplierId: string, platform: ERutterPlatform, accessToken: string): Promise<RutterConnectionStatus> {
    const url: string = `${BUSHWHACK_API}/rutter/ap/connectionStatus/${supplierId}`;

    return axios.get(url, { params: { accessToken } }).then((response) => response.data);
}
