import { GetActivityResponse,
    GetAttachmentsResponse,
    getBills,
    GetBillsResponse,
    getInvoiceAttachments,
    getTransaction,
    getTransactionActivity,
    GetTransactionActivityParams,
    GetTransactionByIDParams,
    getTransactionItems,
    GetTransactionItemsParams,
    GetTransactionItemsResponse,
    GetTransactionParams,
    getTransactionPayouts,
    GetTransactionPayoutsParams,
    GetTransactionPayoutsResponse,
    getTransactions,
    GetTransactionsResponse, Transaction } from '@ar/network/AccountsReceivable.network';
import { useQueries, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

export const FETCH_INVOICE_ATTACHMENTS_QUERY_KEY = 'FETCH_INVOICE_ATTACHMENTS_QUERY_KEY';
export const useGetInvoiceAttachments = (invoiceID: string, rutterConfigID: string, extension: string): UseQueryResult<GetAttachmentsResponse> => useQuery([FETCH_INVOICE_ATTACHMENTS_QUERY_KEY, invoiceID, rutterConfigID], async () => getInvoiceAttachments(invoiceID, rutterConfigID, extension), { enabled: true });

export const FETCH_TRANSACTIONS_QUERY_KEY = 'FETCH_TRANSACTIONS_QUERY_KEY';

export type TUseGetTransactionsQuery = {
    searchParams: GetTransactionParams,
};
export const useGetTransactions = ({ searchParams }: TUseGetTransactionsQuery): UseQueryResult<GetTransactionsResponse> => useQuery([FETCH_TRANSACTIONS_QUERY_KEY, searchParams], async () => getTransactions(searchParams), { enabled: !!searchParams.supplier_id });

export const FETCH_TRANSACTION_QUERY_KEY = 'FETCH_TRANSACTION_QUERY_KEY';
export const useGetTransaction = (params: GetTransactionByIDParams, options?: UseQueryOptions<Transaction>): UseQueryResult<Transaction> => useQuery(
    [FETCH_TRANSACTION_QUERY_KEY, params],
    async () => getTransaction(params),
    { enabled: !!params.transactionID && (!!params.supplier_id || !!params.gpo_id),
        ...options }
);

export const FETCH_TRANSACTION_ITEMS_QUERY_KEY = 'FETCH_TRANSACTION_ITEMS_QUERY_KEY';
export const useGetTransactionItems = (params: GetTransactionItemsParams): UseQueryResult<GetTransactionItemsResponse> => useQuery(
    [FETCH_TRANSACTION_ITEMS_QUERY_KEY, params],
    async () => getTransactionItems(params),
    { enabled: !!params.transactionID
        && (!!params.supplier_id || !!params.customer_id || !!params.gpo_id) }
);

export const FETCH_TRANSACTION_ACTIVITY_QUERY_KEY = 'FETCH_TRANSACTION_ACTIVITY_QUERY_KEY';
export const useGetTransactionActivity = (params: GetTransactionActivityParams): UseQueryResult<GetActivityResponse> => useQuery(
    [FETCH_TRANSACTION_ACTIVITY_QUERY_KEY, params],
    async () => getTransactionActivity(params),
    { enabled: !!params.entity_id && (!!params.supplier_id || !!params.customer_id || !!params.gpo_id) }
);

export const FETCH_TRANSACTION_PAYOUTS_QUERY_KEY = 'FETCH_TRANSACTION_PAYOUTS_QUERY_KEY';
export const useGetTransactionPayouts = (params: GetTransactionPayoutsParams): UseQueryResult<GetTransactionPayoutsResponse> => useQuery(
    [FETCH_TRANSACTION_PAYOUTS_QUERY_KEY, params],
    async () => getTransactionPayouts(params),
    { enabled: !!params.transactionID && (!!params.supplier_id || !!params.customer_id || !!params.gpo_id) }
);

export const FETCH_BILLS_QUERY_KEY = 'FETCH_BILLS_QUERY_KEY';

export const useGetBills = ({ searchParams }: TUseGetTransactionsQuery): UseQueryResult<GetBillsResponse> => useQuery([FETCH_BILLS_QUERY_KEY, searchParams], async () => getBills(searchParams), { enabled: !!searchParams.customer_id });

export const useGetTransactionActivityByEntityIDs = (entityIDs:string[], params: GetTransactionActivityParams) => useQueries({
    queries: entityIDs.map((entityID) => {
        const payload:GetTransactionActivityParams = { ...params, entity_id: entityID };
        return ({
            queryFn: () => getTransactionActivity(payload),
            queryKey: [FETCH_TRANSACTION_ACTIVITY_QUERY_KEY, payload],
            enabled: !!payload.entity_id && entityIDs.length > 0 && (!!payload.supplier_id || !!payload.customer_id || !!payload.gpo_id),
        });
    }),
});
