import '../../../styles/dataPage.scss';
import React, { useState } from 'react';
import { ActionBar, Button, DataGridCheckbox, Loading, NotchDataGrid, toast, Typography } from '@notch-ordering/ui-components';
import { PageTitle } from '@ar/components/PageTitle';
import { useInvoiceStore } from '@ar/stores/InvoicesStore';
import { FETCH_TRANSACTIONS_QUERY_KEY, useGetTransactions } from '@ar/hooks/queries/InvoicesQueries.hook';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { GridSortModel } from '@mui/x-data-grid';
import { getColumns, INVOICE_COLUMNS } from '@ar/pages/InvoicesPage/InvoicePageConstants';
import { EBatchIngestionType, EIntegrationType, GetTransactionSortBy, sendInvoiceNotification, updateTransaction } from '@ar/network/AccountsReceivable.network';
import { InvoiceFilters } from '@ar/components/Invoices/InvoiceFilters/InvoiceFilters';
import { DoNotChargeModal } from '@ar/components/Invoices/Modals/DoNotChargeModal';
import { RefundCustomerModal } from '@ar/components/Invoices/Modals/RefundCustomerModal';
import { ManualChargeModal } from '@ar/components/Invoices/Modals/ManualChargeModal';
import { useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { CreatePaymentMethod } from '@ar/components/shared/modals/CreatePaymentMethod/CreatePaymentMethod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CheckIcon from '@icons/check-icon.svg';
import { BulkDoNotChargeModal } from '@ar/components/Invoices/Modals/BulkDoNotChargeModal';
import { BulkManualChargeModal } from '@ar/components/Invoices/Modals/BulkManualChargeModal/BulkManualChargeModal';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { ARRoutePaths } from '@v2/constants/EPaths';
import { useNavigate } from 'react-router-dom';
import { getGpoId } from '@v2/utils/GPOUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { showInvoiceStatusUpdatedToast, invalidateQueries, showInvoiceStatusNotUpdatedToast } from '@/ar/components/Invoices/InvoiceActionButtonColumn/InvoiceActionButtonColumn';
import { useAmplitude, AmplitudeEventActions, AmplitudeNotificationType, InvoiceNotificationDispatchPage } from '@/containers/app/AmplitudeContext';
import { UploadInvoicesModal } from '@/ar/components/Invoices/Modals/UploadInvoicesModal/UploadInvoicesModal';
import { FeatureFlags } from '@/constants/FeatureFlags';

/**
 * The AR Invoices page
 *
 * @returns JSX Element
 */
export const InvoicesPage = function (): JSX.Element {
    useDocumentTitle('Invoices - Notch');
    const navigate = useNavigate();
    const { trackAmplitudeEvent } = useAmplitude();
    const flags = useFlags<FeatureFlags>();
    const {
        searchParams, updateSearchParams, haveSearchParamsChanged, modals, setModal,
        setSelectedInvoicesModel, setSelectedInvoices, selectedInvoices, selectedInvoicesModel, clearSelectedInvoices,
        getDoNotChargeInvoices,
        getCurrentDncInvoices,
        getReadyToBeChargedInvoices,
    } = useInvoiceStore();
    const { supplierLoginData } = useSupplierStore();
    const gpoId = getGpoId(supplierLoginData);
    const { data: transactionsResponse, status, isLoading: isLoadingTransaction } = useGetTransactions({ searchParams: {
        ...searchParams,
        offset: searchParams.page * searchParams.limit,
        supplier_id: supplierLoginData?.supplier_id,
        gpo_id: gpoId,
    } });
    const supplier = useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
        gpoId,
    });
    const isLoading = isLoadingTransaction || supplier.isLoading;
    const supplierHasInvoiceIngestionEnabled = supplier?.data?.integration_name === EIntegrationType.CsvImport;
    const [hoveredRowID, setHoveredRowID] = useState<string>('');
    const invoices = transactionsResponse?.transactions || [];
    const invoiceRows = invoices.map((invoice) => ({
        ...invoice,
        isHovered: hoveredRowID === invoice.id
    }));
    const hasResults = invoices.length > 0;
    const successStatus = status === 'success';
    const showResults = successStatus && hasResults;
    const emptyFilteredResults = successStatus && !hasResults && haveSearchParamsChanged();
    const noResults = successStatus && transactionsResponse?.total === 0 && !haveSearchParamsChanged();
    const currentTransaction = modals?.transaction;
    const invoicesSelectedNumber = selectedInvoicesModel.length;
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading: isSendingInvoice } = useMutation(sendInvoiceNotification);

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        const { field, sort } = sortModel[0] ?? {
            field: undefined,
            sort: undefined,
        };

        if (!field || !sort) {
            updateSearchParams({ sort_by: undefined });
            return;
        }

        const direction = sort === 'asc' ? '' : '-';
        const fieldToSortBy:GetTransactionSortBy = `${direction}${field}` as GetTransactionSortBy;
        updateSearchParams({
            sort_by: fieldToSortBy,
        });
    }, []);

    const handleCloseCreatePaymentMethod = (): void => {
        setModal('addPaymentMethod', false, currentTransaction);
    };

    const handleSendInvoices = (): void => {
        const promises = selectedInvoicesModel.map((id) => mutateAsync({
            transactionID: id,
            supplierID: supplierLoginData?.supplier_id,
            gpoId,
        }));

        Promise.all(promises).then(() => {
            toast.show({
                message: `${invoicesSelectedNumber} ${invoicesSelectedNumber === 1 ? 'invoice' : 'invoices'} sent successfully`,
                icon: <CheckIcon/>,
            });

            trackAmplitudeEvent(
                AmplitudeEventActions.manualInvoiceNotificationSent,
                {
                    type: AmplitudeNotificationType.BulkNotifications,
                    page: InvoiceNotificationDispatchPage.InvoicesPage,
                    invoices: selectedInvoicesModel.join(', '),
                    invoicesCount: selectedInvoicesModel.length,
                }
            );

            clearSelectedInvoices();
        }).catch((error) => {
            console.error(error);
            toast.show({
                message: 'Failed to send invoices',
            });
        });
        sendTrackingEvent(TrackingEvents.sendInvoiceClicked);
    };

    const updateTransactionMutation = useMutation(updateTransaction);
    const handleUndoDncInvoices = (): void => {
        const promises = getCurrentDncInvoices().map((invoice) => updateTransactionMutation.mutateAsync({
            supplierID: supplierLoginData?.supplier_id,
            gpoId,
            transactionID: invoice.id,
            body: {
                do_no_charge: false,
            },
        }));

        Promise.all(promises).then(() => {
            showInvoiceStatusUpdatedToast();
            clearSelectedInvoices();
            invalidateQueries(queryClient);
        }).catch((error) => {
            console.error(error);
            showInvoiceStatusNotUpdatedToast();
        });

        sendTrackingEvent(TrackingEvents.openInvoiceClicked);
    };

    const onSelectionModelChange = (selected): void => {
        setSelectedInvoicesModel(selected);
        // storing the selected invoices in a map to avoid looping through the invoices array
        setSelectedInvoices(selected.reduce((acc, id) => {
            const invoice = invoices.find((inv) => inv.id === id);
            if (invoice) {
                acc[id] = invoice;
            }
            return acc;
        }, { ...selectedInvoices }));
        sendTrackingEvent(TrackingEvents.invoiceSelected);
    };
    const getColumnsVisibilityModel = (): Record<string, boolean> => {
        // if keys are not present in the object by default, all the columns are visible.
        if (supplierLoginData.gpo) {
            return {};
        }
        const visibilityModel = {
            [INVOICE_COLUMNS.supplier]: false,
        };

        return visibilityModel;
    };

    return (
        <>

            <div className="flex items-start"><PageTitle>Invoices</PageTitle>
                {supplierHasInvoiceIngestionEnabled && <Button variant="SECONDARY"
                    size="SMALL"
                    className="ml-auto"
                    onClick={(): void => {
                        setModal('uploadInvoices', true);
                    }
                    }>Upload invoices</Button>}

            </div>

            <div className="mt-2">
                <InvoiceFilters/>
                {isLoading && <Loading isDark className="mt-10" />}
                {showResults && <>
                    <NotchDataGrid
                        autoHeight
                        loading={isLoading}
                        columnVisibilityModel={getColumnsVisibilityModel()}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableSelectionOnClick
                        disableColumnFilter
                        checkboxSelection
                        keepNonExistentRowsSelected
                        components={{
                            BaseCheckbox: DataGridCheckbox,
                        }}
                        onSelectionModelChange={onSelectionModelChange}
                        selectionModel={selectedInvoicesModel}
                        disableColumnSelector
                        disableColumnMenu
                        columns={getColumns(flags?.supplierPublicInvoiceLink)}
                        page={searchParams.page}
                        rowCount={transactionsResponse?.total ?? 0}
                        rowsPerPageOptions={[searchParams.limit]}
                        pageSize={searchParams.limit}
                        paginationMode="server"
                        rowHeight={61}
                        initialState={{
                            sorting: {
                                sortModel: [{
                                    field: searchParams.sort_by?.replace(/^-/, ''),
                                    sort: searchParams.sort_by?.startsWith('-') ? 'desc' : 'asc',
                                }],
                            }
                        }}
                        onPageChange={(newPage): void => {
                            updateSearchParams({ page: newPage });
                            const pageChangeEvent = newPage > searchParams.page ? TrackingEvents.goToNextPageClicked : TrackingEvents.goToPreviousPageClicked;
                            sendTrackingEvent(pageChangeEvent);
                        }}
                        rows={invoiceRows}
                        onRowClick={({ row }) => {
                            if (!row.id) return;
                            navigate(`/${ARRoutePaths.AR_INVOICES}/${row.id}`);
                        }}
                        componentsProps={{
                            row: {
                                onMouseEnter: (event: React.MouseEvent<HTMLElement>) => {
                                    const rowID = event.currentTarget.getAttribute('data-id');
                                    if (rowID) {
                                        setHoveredRowID(rowID);
                                    }
                                },
                                onMouseLeave: () => setHoveredRowID(null)
                            }
                        }}
                        sx={{
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                            },
                        }}/>
                </>
                }
                {emptyFilteredResults && <div className="flex flex-col gap-2 items-center justify-center mt-32">
                    <Typography variant="LG-2" weight="font-semibold">No results found</Typography>
                    <Typography className="text-gray-600">Try searching for something else or adjusting your filters</Typography>
                </div>
                }
                {noResults && <div className="flex flex-col gap-2 items-center justify-center mt-32">
                    <Typography variant="LG-2" weight="font-semibold">No results found</Typography>
                    <Typography className="text-gray-600">No invoices found for this supplier</Typography>
                </div>
                }
            </div>
            <DoNotChargeModal/>
            <BulkDoNotChargeModal/>
            <RefundCustomerModal/>
            <ManualChargeModal/>
            <BulkManualChargeModal/>
            { supplierHasInvoiceIngestionEnabled && <UploadInvoicesModal
                errorDescription="Please review the errors that occurred during the bulk upload of invoice data using a .csv file. Ensure that your file meets our format specifications to avoid these issues in the future."
                errorTitle="Invoice Upload Summary"
                onClose={async (): Promise<void> => {
                    setModal('uploadInvoices', false, null);
                    await queryClient.invalidateQueries([FETCH_TRANSACTIONS_QUERY_KEY]);
                }}
                isModalOpen={modals?.uploadInvoices}
                ingestionType={EBatchIngestionType.INVOICE}
                subHeaderText="Upload Your Invoices via CSV"
                description="Ensure it meets our format specifications to avoid errors."
                title="Upload invoices"/>}
            <CreatePaymentMethod
                isOpen={modals?.addPaymentMethod}
                handleClose={handleCloseCreatePaymentMethod}
                customerID={currentTransaction?.customer_id}
                subTitle={currentTransaction?.business_name}/>

            {(invoicesSelectedNumber > 0) && <div className="flex justify-center items-center">
                <ActionBar
                    text={`${invoicesSelectedNumber} ${invoicesSelectedNumber === 1 ? 'invoice' : 'invoices'} selected`}
                    isOpen={invoicesSelectedNumber > 0}
                    close={():void => {
                        clearSelectedInvoices();
                        sendTrackingEvent(TrackingEvents.exitActionPanel);
                    }}
                    selection={selectedInvoicesModel}
                    onClose={clearSelectedInvoices}
                    buttons={[
                        {
                            variant: 'TERTIARY_DARK',
                            onClick: handleSendInvoices,
                            loading: isSendingInvoice,
                            text: `Send ${invoicesSelectedNumber === 1 ? 'Invoice' : 'Invoices'}`,
                        },
                        {
                            variant: 'TERTIARY_DARK',
                            onClick: ():void => {
                                setModal('bulkDoNotChargeInvoice', true);
                                sendTrackingEvent(TrackingEvents.doNotChargeClicked);
                            },
                            hidden: getDoNotChargeInvoices().length === 0,
                            text: `Do not charge (${getDoNotChargeInvoices().length})`,
                        },
                        {
                            variant: 'TERTIARY_DARK',
                            onClick: handleUndoDncInvoices,
                            hidden: getCurrentDncInvoices().length === 0,
                            text: `Undo don't charge (${getCurrentDncInvoices().length})`,
                        },
                        {
                            variant: 'TERTIARY_DARK',
                            onClick: ():void => {
                                setModal('bulkManualCharge', true);
                                sendTrackingEvent(TrackingEvents.chargeInvoiceClicked);
                            },
                            hidden: getReadyToBeChargedInvoices().length === 0,
                            text: `Charge (${getReadyToBeChargedInvoices().length})`,
                        },

                    ]}/>
            </div>}
        </>

    );
};
