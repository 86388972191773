import React from 'react';
import DangerIcon from '@icons/danger-icon.svg';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import { ConfirmModal } from '../shared/ConfirmModal';
import { useDraftInvoiceStore } from '@/ar/stores/DraftInvoiceStore';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { queryClient } from '@/containers/app/Root';
import { FETCH_TRANSACTION_ITEMS_QUERY_KEY } from '@/ar/hooks/queries/InvoicesQueries.hook';
import { deleteTransactionItem } from '@/ar/network/AccountsReceivable.network';

export const DraftInvoiceItemDeleteModal : React.FC = () => {
    const { modals, closeModal } = useDraftInvoiceStore();
    const { selectedItem, deleteItemModal } = modals;
    const { supplierLoginData } = useSupplierStore();
    const deleteItemModalMutation = useMutation(deleteTransactionItem, {
        onSuccess: () => {
            closeModal('deleteItemModal');
            toast.show({
                message: 'Product deleted successfully',
                icon: <CheckIcon />,
                showClose: false,
            });
            queryClient.invalidateQueries([FETCH_TRANSACTION_ITEMS_QUERY_KEY]);
        },
        onError: () => {
            toast.show({
                message: 'Failed to delete product',
                showClose: false,
            });
        }
    });
    const onConfirm = () => {
        deleteItemModalMutation.mutate({
            supplierID: supplierLoginData?.supplier_id,
            itemID: selectedItem?.id
        });
    };
    const onClose = () => {
        closeModal('deleteItemModal');
    };
    if (!selectedItem) return null;
    return <ConfirmModal isOpen={deleteItemModal}
        title={<div className="flex flex-col gap-4">
            <DangerIcon className="w-8 h-8 text-red-500" />
            <div>Are you sure you want to delete {selectedItem?.description} product?</div>
        </div>}
        confirmVariant={'DESTRUCTIVE'}
        confirmLabel={'Delete'}
        isLoading={deleteItemModalMutation.isLoading}
        description={'This action cannot be undone'}
        onConfirm={onConfirm}
        onClose={onClose} />;
};
