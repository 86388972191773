import '../../../styles/dataPage.scss';
import React, { useEffect, useMemo } from 'react';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { Button, Input, Loading, NotchDataGrid, Separator, Typography } from '@notch-ordering/ui-components';
import { ARRoutePaths } from '@v2/constants/EPaths';
import BackIcon from '@icons/back-icon.svg';
import ArrowsBidirectionalIcon from '@icons/arrows-bidirectional-icon.svg';
import ReSyncIcon from '@icons/arrows-bidirectional-icon.svg';
import { PageTitle } from '@ar/components/PageTitle';
import { useGetTransaction, useGetTransactionItems, useGetTransactionPayouts } from '@ar/hooks/queries/InvoicesQueries.hook';
import { InvoiceStatusBadge } from '@ar/components/Invoices/InvoiceStatusBadge';
import { GetTransactionItem, Transaction, TransactionStatus } from '@ar/network/AccountsReceivable.network';
import { SeparatorInternalPageFullWidth } from '@ar/components/shared/SeparatorInternalPageFullWidth';
import { SectionTitle } from '@ar/components/shared/SectionTitle';
import { EmptyState, getInvoiceItemColumns, gridSX, PanelBlock } from '@ar/pages/DraftInvoiceDetailsPage/DraftInvoiceDetailsPageConstants';
import { useGetCustomer, useGetCustomerPaymentMethods } from '@ar/hooks/queries/CustomerQueries.hook';
import { getFlagIconByCurrency } from '@ar/pages/PayoutDetailsPage/PayoutDetailsPageConstants';
import PaymentMethodBadge from '@ar/components/shared/PaymentMethodBadge/PaymentMethodBadge';
import { getGpoId } from '@v2/utils/GPOUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { DatePickerInput } from '@v2/components/Shared/DatePickerInput/DatePickerInput';
import AddIcon from '@icons/add-icon.svg';
import { CurrencyInput } from '@v2/components/Shared/InputCurrency';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';
import { centsToDollars, formatAsCurrency, formatPhoneNumber } from '@/utils/Formatters';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { useAutoPayMethod } from '@/ar/hooks/queries/AutoPayMethod.hook';
import { getPaymentLink } from '@/utils/InvoiceUtils';
import { CopyButton, CopyButtonFeedback } from '@/ar/components/shared/CopyButton';
import { FeatureFlags } from '@/constants/FeatureFlags';
import { DraftInvoiceItemFormModal } from '@/ar/components/DraftInvoiceDetails/DraftInvoiceItemFormModal';
import { useDraftInvoiceStore } from '@/ar/stores/DraftInvoiceStore';
import { DraftInvoiceItemDeleteModal } from '@/ar/components/DraftInvoiceDetails/DraftInvoiceItemDeleteModal';

export const syncWithQBOPopoverLabel = ({ label } = { label: 'Sync to Quickbooks Online' }) => (
    <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
        <div className="w-4"><ArrowsBidirectionalIcon className="w-4 h-4 mt-0.5 "/></div>
        <div className="flex flex-col truncate">
            <div>{label}</div>
        </div>
    </Typography>
);

export const reSyncLabel = ({ label } = { label: 'Sync payment' }) => (
    <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
        <div className="w-4"><ReSyncIcon className="w-4 h-4 mt-0.5 "/></div>
        <div className="flex flex-col truncate">
            <div>{label}</div>
        </div>
    </Typography>
);

const calculateInvoiceTotalsByItems = (items: GetTransactionItem[]) => {
    const subtotal = items.reduce((acc, item) => acc + (item.price * item.quantity), 0);
    const taxAmount = items.reduce((acc, item) => acc + item.tax_amount, 0);
    const total = subtotal + taxAmount;
    return {
        subtotal: centsToDollars(subtotal),
        taxAmount: centsToDollars(taxAmount),
        total: centsToDollars(total)
    };
};
/**
 * The AR Draft Invoices detail page
 *
 * @returns JSX Element
 */
export const DraftInvoiceDetailsPage = function PayoutsPage(): JSX.Element {
    useDocumentTitle('Draft Invoice Details - Notch');
    const flags = useFlags<FeatureFlags>();

    const { supplierLoginData, supplier } = useSupplierStore();
    const { openModal } = useDraftInvoiceStore();
    const gpoId = getGpoId(supplierLoginData);
    const { invoiceID } = useParams();

    const mapInvoiceValuesToForm = (invoice: Transaction): Partial<Transaction> => ({
        invoice_number: invoice.invoice_number,
        invoice_date: invoice.invoice_date,
        due_date: invoice.due_date,
        total: centsToDollars(invoice?.total ?? 0),
        paid_offline: centsToDollars(invoice?.paid_offline ?? 0),
        outstanding_amount: centsToDollars(invoice?.outstanding_amount ?? 0),
    });

    const { data: invoice, isLoading, isError: isErrorInvoice } = useGetTransaction(
        {
            supplier_id: supplierLoginData?.supplier_id,
            transactionID: invoiceID,
            gpo_id: gpoId
        },
        // this means that the data will be cached forever or only loaded once and never refetched
        {
            staleTime: Infinity,
            cacheTime: Infinity
        }
    );
    const { handleSubmit, control, reset, setValue, watch } = useForm<Transaction>({
        defaultValues: { ...invoice },
        mode: 'onBlur',
    });

    const { errors } = useFormState({
        control,
    });

    useEffect(() => {
        if (invoice) {
            reset(mapInvoiceValuesToForm(invoice));
        }
    }, [invoice, setValue]);

    const onSubmit = (data) => {
        console.log(data, 'submitting data');
        // Add your update logic here
    };

    const { data: invoiceItemsResponse, isLoading: isLoadingInvoiceItems, isFetching: isFetchingItems } = useGetTransactionItems({
        transactionID: invoice?.id,
        supplier_id: supplierLoginData?.supplier_id,
        gpo_id: gpoId,
    });

    const { data: customerResults, isLoading: isLoadingCustomer } = useGetCustomer({
        customerID: invoice?.customer_id,
        supplierID: supplierLoginData?.supplier_id,
        gpoId,
        supplierIdFilter: invoice?.supplier_id
    });

    const { data: paymentMethods } = useGetCustomerPaymentMethods({
        customerID: invoice?.customer_id,
        supplierID: invoice?.supplier_id,
        gpoId
    });

    const { data: invoicePayoutsResponse, isLoading: isLoadingPayouts } = useGetTransactionPayouts({
        supplier_id: supplierLoginData?.supplier_id,
        gpo_id: gpoId,
        transactionID: invoice?.id,
    });

    const payouts = invoicePayoutsResponse?.payouts ?? [];
    const hasPayouts = !isLoadingPayouts && payouts.length > 0;

    const invoiceItems = invoiceItemsResponse?.items ?? [];
    const items = invoiceItems.filter((item) => item.type === 'invoice') ?? [];
    const hasItems = !isLoadingInvoiceItems && items.length > 0;
    const navigate = useNavigate();

    const [autoPayMethod] = useAutoPayMethod(invoice?.supplier_id, supplierLoginData?.isCustomer, paymentMethods);

    const { isFetching: isSupplierLoading } = useGetSupplier({
        supplierID: invoice?.supplier_id || supplierLoginData?.supplier_id,
        gpoId
    });

    const handleBackButton = (): void => {
        const { history } = window;

        if (history.length > 1) {
            navigate(-1);
        } else {
            navigate(`/${ARRoutePaths.AR_INVOICES}`);
        }
    };

    const paymentLink = getPaymentLink(flags?.supplierPublicInvoiceLink, supplier.id, invoice?.invoice_number, invoice?.payment_link_url);

    const {
        subtotal,
        taxAmount,
    } = useMemo(() => calculateInvoiceTotalsByItems(items), [items]);

    const total = watch('total');
    const paidOffline = watch('paid_offline');

    useEffect(() => {
        const outstandingAmount = total - paidOffline;
        setValue('outstanding_amount', outstandingAmount);
    }, [total, paidOffline]);

    const handleLineItemsTotalCalculation = (): void => {
        const newTotal = calculateInvoiceTotalsByItems(items).total;
        setValue('total', newTotal);
    };

    useEffect(() => {
        if (hasItems) {
            handleLineItemsTotalCalculation();
        } else {
            setValue('total', centsToDollars(invoice?.total));
        }
    }, [hasItems, isFetchingItems]);

    if (isLoading) {
        return <Loading isDark />;
    }

    if (isErrorInvoice) {
        return (
            <>
                <section className="flex gap-2 justify-between items-center  mb-6 ">
                    <Button className="flex items-center -ml-3.5 text-gray-600"
                        as="div"
                        size="SMALL"
                        variant="SUBDUED_ICON"
                        onClick={handleBackButton}>
                        <BackIcon className="w-4 h-4 mr-3"/>
                        Back
                    </Button>
                </section>
                <div className="flex flex-col gap-4 ">
                    <PageTitle>Invoice not found</PageTitle>
                    <Typography as="div" className="text-gray-600">Apologies, no invoice matching the given criteria could be found in the system.</Typography>
                </div>
            </>
        );
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <section className="flex gap-2 justify-between items-center  mb-6 ">
                <Button className="flex items-center -ml-3.5 text-gray-600"
                    size="SMALL"
                    variant="SUBDUED_ICON"
                    as="div"
                    onClick={handleBackButton}>
                    <BackIcon className="w-4 h-4 mr-3"/>
                    Back
                </Button>
            </section>
            <section className="pb-8">
                <div className="flex gap-3 items-center">
                    <PageTitle>{formatAsCurrency(centsToDollars(invoice?.total))}</PageTitle>
                    <InvoiceStatusBadge status={invoice.status as TransactionStatus}/>
                </div>
                <Typography className="text-gray-600 mt-1" as="div"><div className="flex gap-2 item-center">
                    <span>Invoice #{invoice.invoice_number}</span>
                    <CopyButton buttonClassName="p-0"
                        textToCopy={invoice.invoice_number}
                        feedbackType={CopyButtonFeedback.TOAST}
                        successMessage="Invoice number copied"
                        errorMessage="Error copying invoice number to clipboard" />
                </div>
                </Typography>
            </section>
            <SeparatorInternalPageFullWidth className="pb-px"/>
            <section className="flex lg:flex-row flex-col-reverse">
                <div className="-ml-10 py-10 pl-8 lg:w-5/12 w-full">
                    {isLoadingCustomer && <Loading isDark/>}
                    {!isLoadingCustomer && (
                        <div className="flex flex-col gap-8">
                            {invoice.invoice_pdf_url && (
                                <PanelBlock title={'Invoice PDF'}
                                    value={
                                        <iframe
                                            src={`${invoice.invoice_pdf_url}#toolbar=1&navpanes=0&scrollbar=0&view=fitH`}
                                            width="100%"
                                            height="600px"
                                            title="Invoice PDF"
                                            style={{ border: 'none' }}/>
                                    }/>
                            )}
                            <PanelBlock title={'Customer Name'} value={<Link className="text-teal-500" to={`/${ARRoutePaths.AR_CUSTOMERS}/${customerResults.id}`}>{customerResults.name}</Link>}/>
                            <PanelBlock title={'Contact information'}
                                value={<div className="flex gap-1 flex-col truncate">
                                    <div>{customerResults.email}</div>
                                    {!!customerResults.phone && <div>{formatPhoneNumber(customerResults.phone)}</div>}
                                </div>}/>
                            <PanelBlock
                                title={'Issue date'}
                                value={invoice.invoice_date ? <div>{formatIsoStringToUtcDate(new Date(invoice.invoice_date).toISOString(), 'MMM d, yyyy')}</div> : '--'}/>
                            <PanelBlock
                                title={'Due date'}
                                value={invoice.due_date ? <div>{formatIsoStringToUtcDate(new Date(invoice.due_date).toISOString(), 'MMM d, yyyy')}</div> : '--'}/>
                            <PanelBlock title={'Payment terms'} value={customerResults.terms > 1 ? `${customerResults.terms} days` : `${customerResults.terms} day`}/>
                            <PanelBlock title={'Currency'}
                                value={<div className="flex gap-2 items-center">{isSupplierLoading ? <Loading isDark/> : <>
                                    <span>{getFlagIconByCurrency(supplier?.currency)}</span>
                                    <span>{supplier?.currency?.toUpperCase()}</span>
                                </>}</div>}/>
                            <PanelBlock title={'Payment Link'}
                                value={<div className="flex flex-row items-center">
                                    <div className="truncate text-teal-500">{paymentLink}</div>
                                    <CopyButton textToCopy={paymentLink} feedbackType={CopyButtonFeedback.TOAST} successMessage="Invoice link copied" errorMessage="Error copying payment link to clipboard" />
                                </div>} />
                            {invoice.auto_collection && customerResults.has_payment_method && autoPayMethod
                            && <PanelBlock title="AutoPay" value={<PaymentMethodBadge brand={autoPayMethod.brand} last4={autoPayMethod.last4} autoCollection={false} />}/>
                            }
                            {hasPayouts && <PanelBlock title={'Payout reference'}
                                value={<div className="space-y-1">
                                    {payouts?.map((pt) => <Link key={pt.id} className="block text-teal-500" to={`/${ARRoutePaths.AR_PAYOUTS}/${pt.id}`}>
                                        {pt.id.replace('po_', '')}
                                    </Link>)}
                                </div>}/>}
                        </div>
                    )}
                </div>
                <div className="overflow-x-auto  lg:border-r border-r-gray-200 w-full h-full">
                    {isLoading && <Loading isDark/>}
                    {!isLoading && invoice && (
                        <div className="p-10 grid customer-details">
                            <div className="flex flex-col gap-4">
                                <Controller
                                    name="invoice_number"
                                    control={control}
                                    render={({ field }) => <Input
                                        label="Invoice Number"
                                        variant="SMALL"
                                        isInvalid={!!errors.invoice_number}
                                        invalidMessage={errors.invoice_number?.message}
                                        required
                                        inputProps={{
                                            ...field,
                                        }}/>}/>
                                <div className="flex gap-4 items-center ">
                                    <div className="relative w-full">
                                        <Controller
                                            name="invoice_date"
                                            control={control}
                                            render={({ field }) => <DatePickerInput
                                                variant="SMALL"
                                                required
                                                label="Invoice Date"
                                                value={field.value}
                                                onChange={(value): void => field.onChange(value)}
                                                id="invoice_date"/>}/>
                                    </div>
                                    <div className="relative w-full">
                                        <Controller
                                            name="due_date"
                                            control={control}
                                            render={({ field }) => <DatePickerInput
                                                variant="SMALL"
                                                required
                                                label="Due Date"
                                                value={field.value}
                                                onChange={(value): void => field.onChange(value)}
                                                id="due_date"/>}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <section className="p-10">
                        <SectionTitle className="flex items-center gap-2 ">Line items</SectionTitle>

                        {isLoadingInvoiceItems && <Loading isDark/>}
                        {!hasItems && <EmptyState text="There are no items in this invoice."/>}
                        {hasItems && <NotchDataGrid className="overflow-visible"
                            columns={getInvoiceItemColumns()}
                            autoHeight={true}
                            getRowHeight={() => 'auto'}
                            rowCount={items.length}
                            sx={gridSX}
                            sortingMode="client"
                            onRowClick={({ row }) => {
                                openModal('lineItemsModal', row);
                            }}
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            disableColumnMenu
                            rowsPerPageOptions={[50, 100]}
                            hideFooter={true}
                            rows={items}
                            loading={isLoadingInvoiceItems}/>}
                        <button className="bg-transparent mt-4"
                            onClick={(e) => {
                                e.preventDefault();
                                return openModal('lineItemsModal');
                            }}>
                            <Typography className="flex items-center font-medium cursor-pointer gap-2" >
                                <AddIcon className="text-teal-500 w-4 h-4" />
                                <span className="text-teal-500">Add line items</span>
                            </Typography>
                        </button>
                        {hasItems && (
                            <div className="flex justify-end mt-6">
                                <div className="flex flex-col  space-y-3 lg:w-80">
                                    <Separator/>
                                    <div className="flex items-center space-x-4 justify-between">
                                        <Typography className="m-0">Subtotal:</Typography>
                                        <Typography className="m-0 text-right">{formatAsCurrency(subtotal)}</Typography>
                                    </div>
                                    <Separator/>
                                    <div className="flex items-center space-x-4 justify-between">
                                        <Typography className="m-0">Tax Amount:</Typography>
                                        <Typography className="m-0 text-right">{formatAsCurrency(taxAmount)}</Typography>
                                    </div>
                                    <Separator/>
                                </div>

                            </div>
                        )}
                    </section>
                    <Separator/>
                    <section>
                        <div className="p-10 grid customer-details">
                            <div className="flex flex-col gap-4">
                                <div className="flex items-start gap-2">
                                    <Controller
                                        name="total"
                                        control={control}
                                        render={({ field }) => <CurrencyInput
                                            required
                                            allowNegative
                                            isInvalid={!!errors.total}
                                            invalidMessage={errors.total?.message}
                                            variant="SMALL"
                                            disabled={hasItems}
                                            onChange={(e):void => {
                                                field.onChange(e.floatValue);
                                            }}
                                            value={field.value}
                                            label={'Invoice total'}/>}/>
                                </div>
                                <Controller
                                    name="paid_offline"
                                    control={control}
                                    render={({ field }) => <CurrencyInput
                                        required
                                        isInvalid={!!errors.paid_offline}
                                        invalidMessage={errors.paid_offline?.message}
                                        variant="SMALL"
                                        allowNegative
                                        onChange={(e):void => {
                                            field.onChange(e.floatValue);
                                        }}
                                        value={field.value}
                                        label={'Offline payments & credits'}/>}/>
                                {/* TODO: this will be a new field coming from the DB and stored  */}
                                <Controller
                                    name="outstanding_amount"
                                    control={control}
                                    render={({ field }) => <CurrencyInput
                                        required
                                        disabled={!hasItems}
                                        isInvalid={!!errors.outstanding_amount}
                                        invalidMessage={errors.outstanding_amount?.message}
                                        variant="SMALL"
                                        allowNegative
                                        onChange={(e):void => {
                                            field.onChange(e.floatValue);
                                        }}
                                        value={field.value}
                                        label={'Outstanding balance'}/>}/>

                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <DraftInvoiceItemFormModal />
            <DraftInvoiceItemDeleteModal />
        </form>
    );
};
