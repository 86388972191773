import { Input, InputProps } from '@notch-ordering/ui-components';
import React from 'react';
import { IonDatetime } from '@ionic/react';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import cx from 'classnames';

export interface DatePickerInputProps {
    value: InputProps['value'],
    label: InputProps['label'],
    format?: string,
    onChange?: (value: string) => void,
    id?: InputProps['id'],
    onBlur?: InputProps['onBlur'],
    variant?: InputProps['variant'],
    required?: InputProps['required'],
}

export const DatePickerInput : React.FC<DatePickerInputProps> = ({ value, label, onChange, id, onBlur, variant = 'ADVANCED_ALTERNATIVE', required }) => {
    const [isDatePickerHidden, setIsDatePickerHidden] = React.useState(true);
    const refInput = React.useRef<HTMLInputElement>(null);
    const refDatePicker = React.useRef<HTMLIonDatetimeElement>(null);
    React.useEffect(() => {
        const handler = (e: KeyboardEvent): void => {
            if (e.key === 'Escape') {
                setIsDatePickerHidden(true);
            }
        };
        document.addEventListener('keydown', handler);
        // event to detect if the user clicks outside the date picker or input
        const clickHandler = (e: MouseEvent): void => {
            const target = e.target as HTMLElement;
            if (target === refInput.current || target === refDatePicker.current) {
                return;
            }
            setIsDatePickerHidden(true);
        };
        // add event to every element including inputs
        document.addEventListener('click', clickHandler);

        return (): void => {
            document.removeEventListener('keydown', handler);
            document.removeEventListener('click', clickHandler);
        };
    }, []);
    return <><Input variant={variant}
        label={label}
        id={id}
        inputProps={{
            onClick: (): void => {
                setIsDatePickerHidden(false);
            },
            ref: refInput,
        }}
        onFocus={(): void => {
            setIsDatePickerHidden(false);
        }}
        onChange={(e): void => {
            onChange?.(e.target.value);
        }}
        onBlur={onBlur}
        value={value}
        required={required}
        inputClassName={'pr-10'}
        inputIcon={<ChevronDownIcon className="h-4 w-4 text-gray-600" />}/>
    <IonDatetime className={cx('drop-shadow-xl absolute top-20 z-10', { hidden: isDatePickerHidden })}
        value={value as string}
        ref={refDatePicker}
        onIonChange={({ detail: { value: val } }):void => {
            const dateString = val as string;
            onChange(dateString);
            refInput.current?.focus();
            refInput.current?.blur();
            setIsDatePickerHidden(true);
        }}
        presentation="date"/>
    </>;
};
