import create from 'zustand';
import { GetTransactionItem } from '../network/AccountsReceivable.network';

type DraftInvoiceModals = 'lineItemsModal' | 'deleteItemModal';
export type DraftInvoiceStoreState = {
    modals: Partial<Record<DraftInvoiceModals, boolean>> & {
        selectedItem?: GetTransactionItem,
    },

};

type DraftInvoiceStoreActions = {
    setModal: (modal: DraftInvoiceModals, show: boolean) => void,
    openModal: (modalType: DraftInvoiceModals, lineItem?: GetTransactionItem) => void,
    closeModal: (modalType: DraftInvoiceModals) => void, };

export type DraftInvoiceStore = DraftInvoiceStoreState & DraftInvoiceStoreActions;

const DRAFT_INVOICE_STORE_INITIAL_STATE: DraftInvoiceStoreState = {
    modals: {
        selectedItem: null,
        lineItemsModal: false,
        deleteItemModal: false,
    },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const draftInvoiceStoreDefinition = (set, getState): DraftInvoiceStore => ({
    ...DRAFT_INVOICE_STORE_INITIAL_STATE,
    setModal: (modal, show) => set((state) => ({
        modals: {
            ...state.modals,
            [modal]: show,
        },
    })),
    openModal: (modalType, lineItem) => {
        set((state) => ({
            modals: {
                ...state.modals,
                selectedItem: lineItem,
                [modalType]: true,
            },
        }));
    },
    closeModal: (modalType) => {
        set((state) => ({
            modals: {
                ...state.modals,
                selectedItem: null,
                [modalType]: false,
            },
        }));
    },

});

export const useDraftInvoiceStore = create<DraftInvoiceStore>(draftInvoiceStoreDefinition);
